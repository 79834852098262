























































































































































































































import { mdiPencilOutline, mdiDeleteOutline, mdiAccount, mdiPlus } from '@mdi/js';
import Vue from 'vue';
import { minLength, maxLength } from 'vuelidate/lib/validators';
import IconSearch from '@/components/specific/IconSearch.vue';
import { MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIChatMapper } from '@/store/modules/ui/chat';
import { UICommonMapper } from '@/store/modules/ui/common';
import type { Phrase } from '@/store/modules/ui/chat';

const PhraseService = ServiceFactory.get('phrase');

export default Vue.extend({
  name: 'MMessageList',
  components: {
    IconSearch,
  },
  data(): {
    icons: {
      [key: string]: string;
    };
    search: string;
    headers: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    showDefaultTable: boolean;
    tableItems: Phrase[];
    chatList: Phrase[];
    replyList: Phrase[];
    showedDialog: {
      [key: string]: boolean;
    };
    createNewParams: {
      text: string;
    };
    messageInfo: Phrase;
    editParams: {
      text: string;
    };
  } {
    return {
      // 表示順序で記述
      /* eslint-disable vue/sort-keys */
      icons: {
        mdiAccount,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlus,
      },
      search: '',
      headers: [
        {
          text: 'メッセージ',
          sortable: false,
          value: 'text',
          width: '',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '150',
        },
      ],
      showDefaultTable: true,
      tableItems: [],
      chatList: [],
      replyList: [],
      showedDialog: {
        create: false,
        update: false,
        delete: false,
      },
      createNewParams: {
        text: '',
      },
      messageInfo: {
        workspaceId: '',
        kind: 'chat',
        text: '',
        _id: '',
        regDate: '',
        phraseId: '',
      },
      editParams: {
        text: '',
      },
      /* eslint-enable vue/sort-keys */
    };
  },
  computed: {
    ...UIChatMapper.mapState(['phraseList']),
    ...DomainAuthMapper.mapState(['userAttributes']),
  },
  created() {
    this.setTableItem();
  },

  methods: {
    ...UIChatMapper.mapActions(['setPhraseList']),
    ...UICommonMapper.mapActions(['setUserList', 'setNavigating', 'setErrorMessage', 'setMessage']),

    // 表示順序で記述
    /* eslint-disable vue/sort-keys */

    async setTableItem() {
      try {
        this.setNavigating({ navigating: true });

        this.chatList = [];
        this.replyList = [];
        // メッセージ一覧を取得
        const { workspaceId } = this.userAttributes;
        const messageList = await PhraseService.getPhraseList(workspaceId);

        // ストアにセット
        this.setPhraseList({ list: messageList });
        // 送信側メッセージのみセット
        this.chatList = messageList.filter((message: Phrase) => {
          return message.kind === 'chat';
        });
        // 受信側メッセージのみセット
        this.replyList = messageList.filter((message: Phrase) => {
          return message.kind === 'reply';
        });
        if (this.showDefaultTable) {
          this.tableItems = this.chatList;
        } else {
          this.tableItems = this.replyList;
        }
        // モーダル表示リセット
        Object.assign(this.showedDialog, {
          create: false,
          update: false,
          delete: false,
        });
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    switchTableData(kind: string) {
      if (kind === 'chat') {
        this.tableItems = this.chatList;
        this.showDefaultTable = true;
      } else {
        this.tableItems = this.replyList;
        this.showDefaultTable = false;
      }
    },

    customFilter(value: string, search: string, item: Record<string, unknown>) {
      // 検索対象のkey
      const list: string[] = ['text'];
      // 検索対象のvalueのみをセット
      const filterItem: string[] = [];
      Object.entries(item).forEach(([key, fromValue]) => {
        list.forEach((i) => {
          if (key === i && typeof fromValue === 'string') {
            filterItem.push(fromValue);
          }
        });
      });
      const result = filterItem.some((v) => v && v.toString().toLowerCase().includes(search));

      return result;
    },

    // メッセージ追加
    async createMessage() {
      try {
        this.$v.$touch();

        if (this.$v.createNewParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        const kind = this.showDefaultTable ? 'chat' : 'reply';
        const params = {
          workspaceId,
          kind,
          text: this.createNewParams.text,
        };
        await PhraseService.createPhrase(params);

        this.setTableItem();
        this.closeDialog('create');
        this.setMessage({
          color: 'success',
          text: `新しいメッセージを作成しました`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // メッセージ編集ダイアログ表示
    showUpdateDialog(item: Phrase) {
      this.messageInfo = item;
      this.editParams.text = item.text;
      this.showedDialog.update = true;
    },

    // メッセージ編集
    async updateMessage() {
      try {
        this.$v.$touch();

        if (this.$v.editParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });
        const kind = this.showDefaultTable ? 'chat' : 'reply';

        const params = {
          kind,
          text: this.editParams.text,
        };
        const { workspaceId, phraseId } = this.messageInfo;
        await PhraseService.updatePhrase(workspaceId, phraseId, params);

        this.setTableItem();
        this.closeDialog('update');
        this.setMessage({
          color: 'success',
          text: `${MESSAGE_CHANGE_DONE}`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // メッセージ削除ダイアログ表示
    showDeleteDialog(item: Phrase) {
      this.messageInfo = item;
      this.showedDialog.delete = true;
    },

    // メッセージ削除
    async deleteMessage() {
      try {
        this.setNavigating({ navigating: true });

        const { workspaceId, phraseId } = this.messageInfo;
        await PhraseService.deletePhrase(workspaceId, phraseId);

        this.setTableItem();
        this.setMessage({ color: 'success', text: 'メッセージを削除しました。' });
      } catch (error) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
        this.setNavigating({ navigating: false });
      }
    },

    closeDialog(name: string) {
      const key = name;
      this.showedDialog[key] = false;
      switch (name) {
        case 'create':
          this.$v.$reset();
          this.createNewParams.text = '';
          break;
        case 'update':
          this.$v.$reset();
          this.editParams.text = '';
          break;
        default:
          break;
      }
    },
  },
  validations() {
    return {
      createNewParams: {
        text: {
          minLength: minLength(2),
          maxLength: maxLength(128),
        },
      },
      editParams: {
        text: {
          minLength: minLength(2),
          maxLength: maxLength(128),
        },
      },
    };
  },
});
