












































import Vue from 'vue';
import DepartmentList from '@/components/specific/MDepartmentList.vue';
import MessageList from '@/components/specific/MMessageList.vue';
import PositionList from '@/components/specific/MPositionList.vue';
import ProjectList from '@/components/specific/MProjectList.vue';
import UserList from '@/components/specific/MUserList.vue';
import Workspace from '@/components/specific/MWorkspace.vue';
import { UICommonMapper } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'Management',

  components: {
    DepartmentList,
    MessageList,
    PositionList,
    ProjectList,
    UserList,
    Workspace,
  },

  data(): {
    menu: { name: string; title: string }[];
    tab: number;
  } {
    return {
      menu: [
        { name: 'user', title: 'ユーザー管理' },
        { name: 'workspace', title: 'ワークスペース管理' },
        { name: 'department', title: '部署管理' },
        { name: 'position', title: '役職管理' },
        { name: 'project', title: 'プロジェクト管理' },
        { name: 'message', title: 'メッセージ管理' },
      ],
      tab: 0,
    };
  },

  created() {
    this.hideAllPopup();
  },

  methods: {
    ...UICommonMapper.mapActions(['hideAllPopup']),
  },
});
