































































































































































































































































































































































































































































































































































































































































import { mdiAccount, mdiDelete, mdiTextBoxCheckOutline } from '@mdi/js';
import Vue from 'vue';
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import IconSearch from '@/components/specific/IconSearch.vue';
import { ADMIN_LEVEL, MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIDepartmentMapper } from '@/store/modules/ui/department';
import { UIPositionMapper } from '@/store/modules/ui/position';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';
import type { UserAttributes } from '@/store/modules/domain/auth';
import type { User } from '@/store/modules/ui/common';
import type { Position } from '@/store/modules/ui/position';

const AuthService = ServiceFactory.get('auth');
const UserService = ServiceFactory.get('user');
const DepartmentMemberService = ServiceFactory.get('departmentMember');
const DepartmentService = ServiceFactory.get('department');
const PositionService = ServiceFactory.get('position');
const ProjectService = ServiceFactory.get('project');
const VoicexService = ServiceFactory.get('voicex');
const GoogleService = ServiceFactory.get('google');

export default Vue.extend({
  name: 'MUserList',

  components: {
    IconSearch,
  },
  data(): {
    tableItems: User[];
    showDefaultUsers: number;
    users: User[];
    pseudoUsers: User[];
    deletedUsers: User[];
    sendInvitationDialog: boolean;
    icons: {
      [key: string]: string;
    };
    createNewParams: {
      email: string;
      userName: string;
      kana: string;
      adminLevel?: number;
      department?: number;
      project?: string[];
      extNo?: number | string;
    };
    search: string;
    headers: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    adminLevelList: { color: string; name: string; value: number }[];
    departments: string[];
    positions: string[];
    projects: string[];
    phoneNoList: string[];
    // 編集削除関連
    showedDialog: {
      [key: string]: boolean;
    };
    showedContextMenu: boolean;
    showMenuInvitation: boolean;
    editParams: {
      userName: string;
      kana: string;
      adminLevel?: number;
      department?: string;
      position: string;
      phoneNo?: string;
    };
    disabledAdminLevel: boolean;
    showWarning: boolean;
    warningMessage: string;
    // ユーザー情報
    userInfo: UserAttributes;
    isProcessing: boolean;
  } {
    return {
      // 表示順序で記述
      /* eslint-disable vue/sort-keys */
      tableItems: [],
      showDefaultUsers: 1,
      users: [],
      pseudoUsers: [],
      deletedUsers: [],
      sendInvitationDialog: false,
      icons: {
        mdiAccount,
        mdiDelete,
        mdiTextBoxCheckOutline,
      },
      createNewParams: {
        email: '',
        userName: '',
        kana: '',
        adminLevel: undefined,
        department: undefined,
        project: [],
        extNo: undefined,
      },
      search: '',
      headers: [
        {
          text: '',
          sortable: false,
          value: 'status',
          width: '5%',
        },
        {
          text: 'ユーザー名 / メールアドレス',
          sortable: false,
          value: 'userName',
          width: '45%',
        },
        {
          text: '部署 / 役職',
          sortable: false,
          value: 'position',
          width: '45%',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '5%',
        },
      ],
      adminLevelList: [],
      departments: [],
      positions: [],
      projects: [],
      phoneNoList: [],
      showedDialog: {
        create: false,
        invitation: false,
        edit: false,
        delete: false,
      },
      // 編集削除関連
      showedContextMenu: false,
      showMenuInvitation: false,
      editParams: {
        userName: '',
        kana: '',
        adminLevel: undefined,
        department: '',
        position: '',
        phoneNo: '',
      },
      disabledAdminLevel: false,
      showWarning: false,
      warningMessage: '',
      // ユーザー情報
      userInfo: {
        workspaceId: '',
        userId: '',
        adminLevel: undefined,
        email: '',
        google: {
          resourceId: '',
        },
        userName: '',
        kana: '',
        image: '',
        departments: [],
        position: '',
        projects: [],
        phoneNo: '',
        voiceXs: [],
        timezone: '',
        status: undefined,
        emotion: 0,
        place: '',
        device: '',
        currentApp: {
          appId: '',
          startedAt: '',
          memberId: '',
        },
        chatUnread: undefined,
        regDate: '',
        updDate: '',
        webPushToken: [],
        webPushEnabled: false,
      },
      isProcessing: false,
      /* eslint-enable vue/sort-keys */
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['userList']),
    ...UIDepartmentMapper.mapState(['departmentList']),
    ...UIWorkspaceMapper.mapState(['workspace']),
  },
  created() {
    this.showDefaultUsers = 1;
    this.setTableItem();
  },
  methods: {
    ...UICommonMapper.mapActions(['setUserList', 'setNavigating', 'setErrorMessage', 'setMessage']),
    ...UIDepartmentMapper.mapActions(['setDepartmentList']),
    ...UIPositionMapper.mapActions(['setPositionList']),
    ...DomainAuthMapper.mapActions(['setUserAttributesSpecified']),

    // 表示順序で記述
    /* eslint-disable vue/sort-keys */
    async setTableItem() {
      this.users = [];
      this.pseudoUsers = [];
      this.deletedUsers = [];
      const { workspaceId } = this.userAttributes;
      this.setNavigating({ navigating: true });

      try {
        const responseUserList = await UserService.getUserList(workspaceId);

        // ユーザー一覧をストアにセット
        const sortedUserList = this._.sortBy(responseUserList, 'kana');
        this.setUserList({ users: sortedUserList });

        const list = JSON.parse(JSON.stringify(sortedUserList));
        list.forEach((user: User) => {
          if (user.departments) {
            const name = user.departments.map(
              (elm: { departmentName: string }) => elm.departmentName
            );
            this.$set(user, 'departmentName', name);
          }
        });
        // 本登録ユーザーのみセット
        this.users = list.filter((user: User) => {
          return user.status === 1;
        });
        // 仮登録ユーザーのみセット
        this.pseudoUsers = list.filter((user: User) => {
          return user.status === 0;
        });
        // 削除済みユーザーのみセット
        this.deletedUsers = list.filter((user: User) => {
          return user.status === 9;
        });

        switch (this.showDefaultUsers) {
          case 0:
            this.tableItems = this.pseudoUsers;
            this.showDefaultUsers = 0;
            break;
          case 9:
            this.tableItems = this.deletedUsers;
            this.showDefaultUsers = 9;
            break;
          default:
            this.tableItems = this.users;
            this.showDefaultUsers = 1;
            break;
        }
      } catch (error) {
        this.$$log.error(error);
      }

      this.setNavigating({ navigating: false });
    },

    switchTableData(status: number) {
      if (status === 0) {
        this.tableItems = this.pseudoUsers;
        this.showDefaultUsers = 0;
      } else if (status === 1) {
        this.tableItems = this.users;
        this.showDefaultUsers = 1;
      } else {
        this.tableItems = this.deletedUsers;
        this.showDefaultUsers = 9;
      }
    },

    customFilter(value: string, search: string, item: Record<string, unknown>) {
      // 検索対象のkey
      const list: string[] = ['email', 'userName', 'kana', 'departmentName', 'position'];
      // 検索対象のvalueのみをセット
      const filterItem: string[] = [];
      Object.entries(item).forEach(([key, fromValue]) => {
        list.forEach((i) => {
          if (key === i && typeof fromValue === 'string') {
            filterItem.push(fromValue);
          }
        });
      });
      const searchText = search.replace(/[\u3041-\u3096]/g, (ch) =>
        String.fromCharCode(ch.charCodeAt(0) + 0x60)
      );
      const result = filterItem.some((v) => v && v.toString().toLowerCase().includes(searchText));

      return result;
    },

    userNameShorten(user: User | UserAttributes): string {
      // TODO 実際には必ず名前は入っているので、このコードはリリース前に修正する
      return this._.get(user, 'userName', '不明').substring(0, 2);
    },

    async getFormData(e: string) {
      this.setNavigating({ navigating: true });

      const { workspaceId } = this.userAttributes;

      await Promise.all([this.getDepartments(), this.getPositions(), this.getProjects()]);

      try {
        // 内線番号リストを取得
        const extensionList = await VoicexService.getExtensionList({ workspaceId, state: 'A' });
        this.phoneNoList = extensionList.extensions;
        this.phoneNoList.push('設定しない');
      } catch (error: any) {
        this.$$log.error(error);
        // VoiceX が動いてないとき、VoiceXのAPIのサーバーが503を返すので、無視ということに。
        // https://comsquareteam.slack.com/archives/C039NTEGTBM/p1691652575278829
        // this.setMessage({ color: 'error', text: error.message });
      }

      // 管理レベルをセット
      // スーパーアドミンは選択肢から除く
      this.adminLevelList = ADMIN_LEVEL.filter((item) => item.value >= 2);
      if (this.userInfo.status === 0 || this.userInfo.userId === this.userAttributes.userId) {
        this.disabledAdminLevel = true;
      }

      if (e === 'edit') {
        this.editParams.userName = this.userInfo.userName;
        this.editParams.kana = this.userInfo.kana;
        this.editParams.adminLevel = this.userInfo.adminLevel;
        this.editParams.department =
          this.userInfo.departments && this.userInfo.departments.length > 0
            ? this.userInfo.departments[0].departmentId
            : '';
        this.editParams.position = this.userInfo.position;
        if (this.userInfo.voiceXs && this.userInfo.voiceXs.length > 0) {
          this.editParams.phoneNo = this.userInfo.voiceXs[0].phoneNo;
          this.phoneNoList.unshift(this.userInfo.voiceXs[0].phoneNo);
        } else {
          this.editParams.phoneNo = '';
        }
        this.showedDialog.edit = true;
      } else {
        this.showedDialog.create = true;
      }
      this.setNavigating({ navigating: false });
      // スクロールをTopに戻す。
      setTimeout(() => {
        (this.$refs.addUser as Element).scrollTop = 0;
      }, 0);
    },

    async getDepartments() {
      try {
        // // 部署一覧を取得
        const { workspaceId } = this.userAttributes;
        const departmentList = await DepartmentService.getDepartmentList(workspaceId);

        // 部署情報をストアにセット
        this.setDepartmentList({ departments: departmentList });
        this.departments = departmentList;
      } catch (error: any) {
        this.$$log.error(error);
        // 空が返ってきた場合はエラー表示しない
        if (error.status !== 404) {
          this.setMessage({ color: 'error', text: error.message });
        }
      }
    },

    async getPositions() {
      try {
        // 役職一覧を取得
        const { workspaceId } = this.userAttributes;
        const positionList = await PositionService.getPositionList(workspaceId);
        this.setPositionList(positionList);
        const names: string[] = [];
        positionList.map((item: Position) => {
          names.push(item.name);
          return names;
        });
        this.positions = names;
      } catch (error: any) {
        this.$$log.error(error);
        // 空が返ってきた場合はエラー表示しない
        if (error.status !== 404) {
          this.setMessage({ color: 'error', text: error.message });
        }
      }
    },

    async getProjects() {
      try {
        // プロジェクト一覧を取得
        const { workspaceId } = this.userAttributes;
        const projectList = await ProjectService.getProjectList(workspaceId);

        this.projects = projectList.filter(
          (item: {
            projectId: string;
            projectName: string;
            regDate: string;
            workspaceId: string;
            _id: string;
          }) => item.projectId !== 'main'
        );
      } catch (error: any) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    async createUser() {
      const { workspaceId } = this.userAttributes;
      try {
        this.$v.$touch();

        if (this.$v.createNewParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }

        this.setNavigating({ navigating: true });

        // 新しいユーザーを登録
        const newNo =
          this.createNewParams.extNo === '設定しない' ? undefined : this.createNewParams.extNo;
        await UserService.createUser({
          workspaceId,
          email: this.createNewParams.email,
          userName: this.createNewParams.userName,
          kana: this.createNewParams.kana,
          adminLevel: this.createNewParams.adminLevel,
          departmentId: this.createNewParams.department,
          projectIds: this.createNewParams.project,
          extNo: newNo,
        });

        this.closeCreateUserDialog();

        // テーブルにリストをセット
        this.setTableItem();
        this.sendInvitationDialog = true;
      } catch (error: any) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    closeCreateUserDialog() {
      this.showedDialog.create = false;
      this.$v.$reset();
      this.createNewParams.email = '';
      this.createNewParams.userName = '';
      this.createNewParams.kana = '';
      this.createNewParams.adminLevel = undefined;
      this.createNewParams.department = undefined;
      this.createNewParams.project = undefined;
      this.createNewParams.extNo = undefined;
    },

    // 編集削除メニュー
    async toggleContextMenu(item?: User) {
      if (item) {
        // ユーザー情報を取得
        try {
          const responseUser = await UserService.getUser(item.workspaceId, item.userId);
          this.userInfo = responseUser;
          if (this.userInfo.status === 0) {
            this.showMenuInvitation = true;
          } else {
            this.showMenuInvitation = false;
          }
          this.showedContextMenu = true;
        } catch (error: any) {
          this.$$log.error(error);
          this.setMessage({ color: 'error', text: error.message });
        }
      } else {
        this.$v.$reset();
        this.editParams.userName = '';
        this.editParams.kana = '';
        this.editParams.adminLevel = undefined;
        this.editParams.department = undefined;
        this.editParams.phoneNo = undefined;
        this.phoneNoList = [];
        this.showWarning = false;
        this.showedContextMenu = false;
        this.showMenuInvitation = false;
      }
    },

    async editUser() {
      this.$v.$touch();

      const { workspaceId, userId } = this.userInfo;

      if (this.$v.editParams.$invalid) {
        this.$$log.debug(this.$v);
        return;
      }

      // ユーザー情報
      interface UpdateUserParams {
        [key: string]: string | number;
      }
      const updateUserParams: UpdateUserParams = {};

      updateUserParams.kana =
        this.userInfo.kana === this._.trim(this.editParams.kana)
          ? ''
          : this._.trim(this.editParams.kana);
      updateUserParams.userName =
        this.userInfo.userName === this._.trim(this.editParams.userName)
          ? ''
          : this._.trim(this.editParams.userName);
      updateUserParams.adminLevel =
        this.userInfo.adminLevel === this.editParams.adminLevel
          ? ''
          : (this.editParams.adminLevel as number);

      for (const key in updateUserParams) {
        if (updateUserParams[key] === undefined || !updateUserParams[key]) {
          delete updateUserParams[key];
        }
      }

      // 役職は空でも更新可能
      if (
        (!this.userInfo.position && this._.trim(this.editParams.position) !== '') ||
        this.userInfo.position !== this._.trim(this.editParams.position)
      ) {
        updateUserParams.position = this._.trim(this.editParams.position);
      } else {
        delete updateUserParams.position;
      }

      // 部署
      const deptId = this.editParams.department as string;
      const currDeptId =
        this.userInfo.departments && this.userInfo.departments.length > 0
          ? this.userInfo.departments[0].departmentId
          : '';
      const updateMemberParams = {
        workspaceId,
        departmentId: deptId,
        memberId: userId,
        leader: 0,
      };

      // 内線番号
      const newNo =
        this.editParams.phoneNo === '設定しない' ? '' : (this.editParams.phoneNo as string);
      const currNo =
        this.userInfo.voiceXs && this.userInfo.voiceXs.length > 0
          ? this.userInfo.voiceXs[0].phoneNo
          : '';
      const createVxParams = {
        workspaceId,
        userId,
        telNo: newNo,
      };

      this.setNavigating({ navigating: true });

      // トーストエラーメッセージ作成
      let toastErrorMsg = [];
      interface UserErrorMsg {
        [key: string]: string;
      }
      const userErrorMsg: UserErrorMsg = {
        userName: 'ユーザー名',
        kana: 'フリガナ',
        adminLevel: '管理レベル',
        position: '役職',
      };

      if (Object.keys(updateUserParams).length) {
        for (const key in userErrorMsg) {
          if (updateUserParams[key] !== undefined) {
            toastErrorMsg.push(userErrorMsg[key]);
          }
        }
      }
      if (currDeptId !== deptId) {
        toastErrorMsg.push('所属部署');
      }
      if (currNo !== newNo) {
        toastErrorMsg.push('内線番号');
      }

      try {
        // ユーザー情報更新
        if (Object.keys(updateUserParams).length) {
          const responseUser = await AuthService.updateUserAttributes(
            workspaceId,
            userId,
            updateUserParams
          );
          // ユーザー情報をストアにセット
          this.setUserAttributesSpecified(responseUser);
        }
        const messages = toastErrorMsg.concat(Object.values(userErrorMsg));
        const deleteMsg = messages.filter(
          (element, index, array) => !(array.indexOf(element) === index)
        );
        toastErrorMsg = toastErrorMsg.filter((item) => !deleteMsg.includes(item));

        // 部署更新
        if (!this._.isEmpty(currDeptId) && currDeptId !== this.editParams.department) {
          // 変更
          await DepartmentMemberService.deleteDepartmentMember(workspaceId, currDeptId, userId);
          await DepartmentMemberService.createDepartmentMember(updateMemberParams);
        } else if (!currDeptId && this.editParams.department) {
          // 新しく登録
          await DepartmentMemberService.createDepartmentMember(updateMemberParams);
        }
        toastErrorMsg = toastErrorMsg.filter((item) => item !== '所属部署');

        // 内線番号更新
        if (!currNo && newNo) {
          // 新しく設定
          await VoicexService.createVoicexUser(createVxParams);
        } else if (currNo && !newNo) {
          // 内線番号削除
          await VoicexService.deleteVoicexUser(workspaceId, currNo);
        } else if (newNo && currNo !== newNo) {
          // 変更
          await VoicexService.deleteVoicexUser(workspaceId, currNo);
          await VoicexService.createVoicexUser(createVxParams);
        }
        toastErrorMsg = toastErrorMsg.filter((item) => item !== '内線番号');

        // テーブルにリストをセット
        this.setTableItem();
        this.toggleContextMenu();

        this.setMessage({
          color: 'success',
          text: `${MESSAGE_CHANGE_DONE}`,
        });

        if (this.userInfo.userId === this.userAttributes.userId) {
          await AuthService.tokenRefresh({ userId, workspaceId });
        }
        this.closeEditDialog();
      } catch (error) {
        this.$$log.error(error);

        const responseUser = await UserService.getUser(
          this.userInfo.workspaceId,
          this.userInfo.userId
        );
        this.userInfo = responseUser;
        this.getFormData('edit');

        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: `${toastErrorMsg} の変更ができませんでした。` });
      }
    },

    closeEditDialog() {
      this.showedDialog.edit = false;
      this.$v.$reset();
      this.editParams.userName = '';
      this.editParams.kana = '';
      this.editParams.adminLevel = undefined;
      this.editParams.department = undefined;
      this.editParams.phoneNo = undefined;
      this.disabledAdminLevel = false;
    },

    async deleteUser() {
      const { workspaceId, userId, status } = this.userInfo;

      if (this.isProcessing) {
        return;
      }
      if (userId === this.userAttributes.userId) {
        this.showWarning = true;
        this.warningMessage = '現在ログイン中のアカウント削除はできません。';
        return;
      }

      this.isProcessing = true;
      this.setNavigating({ navigating: true });

      try {
        // 内線番号削除
        if (this.userInfo.voiceXs && this.userInfo.voiceXs.length > 0) {
          const telNo = this.userInfo.voiceXs[0].phoneNo;
          await VoicexService.deleteVoicexUser(workspaceId, telNo);
        }

        // 部署からメンバー削除
        if (this.userInfo.departments && this.userInfo.departments.length > 0) {
          const deleteDepartmentId = this.userInfo.departments[0].departmentId;
          await DepartmentMemberService.deleteDepartmentMember(
            workspaceId,
            deleteDepartmentId,
            userId
          );
        }

        // googleOAuthToken削除
        if (this.userInfo.google) {
          await GoogleService.deleteGoogleToken(workspaceId, userId);
        }

        if (status === 0 || status === 9) {
          // 物理削除
          await UserService.purgeUser(workspaceId, userId);
        } else {
          // 論理削除
          await UserService.deleteUser(workspaceId, userId);
        }
        this.closeDeleteDialog();

        this.setMessage({ color: 'success', text: 'アカウントを削除しました。' });

        // テーブルにリストをセット
        this.setTableItem();
        this.toggleContextMenu();
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: 'アカウントの削除に失敗しました。' });
      }
      this.isProcessing = false;
    },

    closeDeleteDialog() {
      this.showWarning = false;
      this.showedDialog.delete = false;
    },

    async toDeleteDelUser(item?: User) {
      // ユーザー情報を取得
      try {
        if (item) {
          const responseUser = await UserService.getUser(item.workspaceId, item.userId);
          this.userInfo = responseUser;
          this.showedDialog.delete = true;
        }
      } catch (error: any) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // 招待メール再発行
    async reissueMail() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;

      try {
        const { workspaceId, userId } = this.userInfo;

        await UserService.onetimeReissue({
          workspaceId,
          userId,
        });
        this.showedDialog.invitation = false;

        this.toggleContextMenu();
        this.isProcessing = false;
        this.sendInvitationDialog = true;
      } catch (error: any) {
        this.$$log.error(error);
        if (error.message === 'session disabled.') {
          // セッション切れの場合の招待メール再送信(再登録処理)
          this.reregistration();
        } else {
          this.setNavigating({ navigating: false });
          this.setMessage({ color: 'error', text: '再送信に失敗しました。' });
          this.isProcessing = false;
        }
      }
    },

    // セッション切れの場合の招待メール再送信(再登録処理)
    async reregistration() {
      const { workspaceId, userId } = this.userInfo;

      const telNo =
        this.userInfo.voiceXs && this.userInfo.voiceXs.length > 0
          ? this.userInfo.voiceXs[0].phoneNo
          : undefined;

      const departmentId =
        this.userInfo.departments && this.userInfo.departments.length > 0
          ? this.userInfo.departments[0].departmentId
          : undefined;

      let toastErrorMsg = '再送信に失敗しました。';

      try {
        // 内線番号削除
        if (telNo) {
          await VoicexService.deleteVoicexUser(workspaceId, telNo);
        }
        // 部署からメンバー削除
        if (departmentId) {
          await DepartmentMemberService.deleteDepartmentMember(workspaceId, departmentId, userId);
        }

        // ユーザー削除
        await UserService.deleteUser(workspaceId, userId);

        toastErrorMsg = '再送信に失敗しました。再度ユーザーを作成してください。';

        // ユーザー登録
        await UserService.createUser({
          workspaceId: this.userInfo.workspaceId,
          email: this.userInfo.email,
          userName: this.userInfo.userName,
          kana: this.userInfo.kana,
          adminLevel: this.userInfo.adminLevel,
          departmentId,
          extNo: telNo,
        });
        this.showedDialog.invitation = false;

        // テーブルにリストをセット
        this.setTableItem();
        this.toggleContextMenu();
        this.sendInvitationDialog = true;
      } catch (error) {
        this.$$log.error(error);

        if (toastErrorMsg !== '再送信に失敗しました。') {
          this.setTableItem();
          this.toggleContextMenu();
          this.showedDialog.invitation = false;
        }

        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: toastErrorMsg });
      }
      this.isProcessing = false;
    },
    /* eslint-enable vue/sort-keys */
  },
  validations() {
    return {
      createNewParams: {
        // 表示の順序で定義する
        /* eslint-disable vue/sort-keys */
        email: {
          required,
          email,
          maxLength: maxLength(128),
        },
        adminLevel: {
          required: helpers.withParams({ type: '_requiredSelect' }, required),
        },
        department: {
          required: helpers.withParams({ type: '_requiredSelect' }, required),
        },
        project: {},
        userName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
        kana: {
          containsOnlyFullWidthKatakanaAndSpace: this.$$validators
            .containsOnlyFullWidthKatakanaAndSpace,
        },
        extNo: {},
        /* eslint-enable vue/sort-keys */
      },
      editParams: {
        // 表示の順序で定義する
        /* eslint-disable vue/sort-keys */
        adminLevel: {
          required: helpers.withParams({ type: '_requiredSelect' }, required),
        },
        department: {},
        position: {
          maxLength: maxLength(32),
        },
        userName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
        kana: {
          containsOnlyFullWidthKatakanaAndSpace: this.$$validators
            .containsOnlyFullWidthKatakanaAndSpace,
        },
        phoneNo: {},
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
