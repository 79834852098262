




































































































































































































































































































































































































































































































































import {
  mdiTextBoxCheckOutline,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiAccount,
  mdiAccountGroupOutline,
  mdiAccountPlusOutline,
  mdiStar,
  mdiAccountStarOutline,
  mdiPlus,
} from '@mdi/js';
import Vue from 'vue';
import { minLength, maxLength } from 'vuelidate/lib/validators';
import IconSearch from '@/components/specific/IconSearch.vue';
import { MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper, User } from '@/store/modules/ui/common';
import { Project, UIProjectMapper } from '@/store/modules/ui/project';
import type { UserAttributes } from '@/store/modules/domain/auth';

const UserService = ServiceFactory.get('user');
const ProjectService = ServiceFactory.get('project');
const ProjectMemberService = ServiceFactory.get('projectMember');

interface Member {
  email?: string;
  image?: string;
  kana?: string;
  memberId: string;
  projectId: string;
  projects: { projectId: string; projectName: string }[];
  regDate: string;
  userName?: string;
  workspaceId: string;
}

export default Vue.extend({
  name: 'MProjectList',
  components: {
    IconSearch,
  },
  data(): {
    icons: {
      [key: string]: string;
    };
    search: string;
    searchMember: string;
    headers: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    tableItems: {
      _id: '';
      workspaceId: '';
      projectId: '';
      projectName: '';
      regDate: '';
      members: Member[];
    }[];
    memberList: Member[];
    memberListHeaders: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    addMemberList: User[];
    addMemberListHeaders: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    showedDialog: {
      [key: string]: boolean;
    };
    userInfo?: UserAttributes;
    showUserProjects: boolean;
    showOtherUserProjects: boolean;
    createNewParams: {
      projectName: string;
    };
    projectInfo: Project;
    showWarning: boolean;
    warningMessage: string;
    editParams: {
      projectName: string;
    };
  } {
    return {
      // 表示順序で記述
      /* eslint-disable vue/sort-keys */
      icons: {
        mdiAccount,
        mdiAccountGroupOutline,
        mdiTextBoxCheckOutline,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiAccountStarOutline,
        mdiAccountPlusOutline,
        mdiStar,
        mdiPlus,
      },
      search: '',
      searchMember: '',
      headers: [
        {
          text: 'プロジェクト名',
          sortable: false,
          value: 'projectName',
          width: '',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '150',
        },
      ],
      tableItems: [],
      memberList: [],
      memberListHeaders: [
        {
          text: '',
          sortable: false,
          value: 'image',
          width: '70',
        },
        {
          text: '',
          sortable: false,
          value: 'userName',
          width: '330',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '90',
        },
      ],
      addMemberList: [],
      addMemberListHeaders: [
        {
          text: '',
          sortable: false,
          value: 'image',
          width: '70',
        },
        {
          text: '',
          sortable: false,
          value: 'userName',
          width: '330',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '90',
        },
      ],
      showedDialog: {
        create: false,
        update: false,
        delete: false,
        deleteMem: false,
        memberList: false,
        addMemberList: false,
      },
      userInfo: undefined,
      showUserProjects: false,
      showOtherUserProjects: false,
      createNewParams: {
        projectName: '',
      },
      projectInfo: {
        _id: '',
        workspaceId: '',
        projectId: '',
        projectName: '',
        regDate: '',
      },
      showWarning: false,
      warningMessage: '',
      editParams: {
        projectName: '',
      },
      /* eslint-enable vue/sort-keys */
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['userList']),
    ...UIProjectMapper.mapState(['projectList']),
  },
  created() {
    this.setTableItem();
  },

  methods: {
    ...UIProjectMapper.mapActions(['setProjectList']),
    ...UICommonMapper.mapActions(['setUserList', 'setNavigating', 'setErrorMessage', 'setMessage']),

    // 表示順序で記述
    /* eslint-disable vue/sort-keys */
    async setTableItem() {
      try {
        this.setNavigating({ navigating: true });

        // プロジェクト一覧を取得
        const { workspaceId } = this.userAttributes;
        const projectList = await ProjectService.getProjectList(workspaceId);

        // プロジェクト情報をストアにセット
        this.setProjectList({ projects: projectList });
        this.tableItems = projectList.filter(
          (item: {
            projectId: string;
            projectName: string;
            regDate: string;
            workspaceId: string;
            _id: string;
          }) => item.projectId !== 'main'
        );

        // モーダル表示リセット
        Object.assign(this.showedDialog, {
          create: false,
          update: false,
          delete: false,
          memberList: false,
          deleteMem: false,
        });
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    customFilter(value: string, search: string, item: Record<string, unknown>) {
      // 検索対象のkey
      const list: string[] = ['email', 'userName', 'kana', 'projectName'];

      // 検索対象のvalueのみをセット
      const filterItem: string[] = [];
      Object.entries(item).forEach(([key, fromValue]) => {
        list.forEach((i) => {
          if (key === i && typeof fromValue === 'string') {
            filterItem.push(fromValue);
          }
        });
      });
      const searchText = search.replace(/[\u3041-\u3096]/g, (ch) =>
        String.fromCharCode(ch.charCodeAt(0) + 0x60)
      );
      const result = filterItem.some((v) => v && v.toString().toLowerCase().includes(searchText));

      return result;
    },

    // プロジェクト追加
    async createProject() {
      try {
        this.$v.$touch();

        if (this.$v.createNewParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        const params = {
          workspaceId,
          projectName: this.createNewParams.projectName,
          mainFlag: 0,
        };
        await ProjectService.createProject(params);

        this.setTableItem();
        this.closeDialog('create');
        this.setMessage({
          color: 'success',
          text: `新しいプロジェクトを作成しました`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    userNameShorten(user: Member | UserAttributes): string {
      // TODO 実際には必ず名前は入っているので、このコードはリリース前に修正する
      return this._.get(user, 'userName', '不明').substring(0, 2);
    },

    // メンバー設定ダイアログ表示
    async showMemberDialog(item?: Project) {
      this.searchMember = '';
      try {
        this.setNavigating({ navigating: true });
        if (item) {
          this.projectInfo = item;
        }
        const { workspaceId, projectId } = this.projectInfo;
        // ユーザーリストを取得
        const responseUserList = await UserService.getUserList(workspaceId);

        // ユーザー一覧をストアにセット
        const sortedUserList = this._.sortBy(responseUserList, 'kana');
        this.setUserList({ users: sortedUserList });

        const list = JSON.parse(JSON.stringify(sortedUserList));
        list.forEach((user: User) => {
          if (user.projects) {
            const name = user.projects.map((elm: { projectName: string }) => elm.projectName);
            this.$set(user, 'projectName', name);
          }
        });
        // メンバーを取得
        const members: Member[] = await ProjectMemberService.getProjectMemberList(
          workspaceId,
          projectId
        );
        // メンバーに必要な情報をユーザー情報から取得し追加
        members.forEach((member, index) => {
          const { memberId } = member;
          this.userList.forEach((user) => {
            if (memberId === user.userId) {
              members[index].image = user.image;
              members[index].userName = user.userName;
              members[index].kana = user.kana;
              members[index].email = user.email;
              members[index].projects = user.projects;
            }
          });
        });
        this.memberList = members;
        this.showedDialog.memberList = true;
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // ユーザー参加プロジェクト取得
    async getUserInfo(id: string, name: string) {
      this.showUserProjects = false;
      this.userInfo = undefined;
      try {
        this.setNavigating({ navigating: true });
        const responseUser = await UserService.getUser(this.projectInfo.workspaceId, id);
        this.userInfo = responseUser;
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
      if (name === 'memProject') {
        this.showUserProjects = true;
      } else if (name === 'otherMemProject') {
        this.showOtherUserProjects = true;
      } else if (name === 'deleteMem') {
        this.showedDialog[name] = true;
      }
    },

    async deleteMember() {
      try {
        this.setNavigating({ navigating: true });
        if (this.userInfo) {
          const { workspaceId, userId } = this.userInfo;
          await ProjectMemberService.deleteProjectMember(
            workspaceId,
            this.projectInfo.projectId,
            userId
          );
          this.setTableItem();
          this.setMessage({ color: 'success', text: 'メンバーを削除しました。' });
        }
      } catch (error) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
        this.setNavigating({ navigating: false });
      }
    },

    // メンバー追加ダイアログ表示
    async showAddMemberDialog() {
      try {
        this.setNavigating({ navigating: true });

        const members: Member[] = JSON.parse(JSON.stringify(this.memberList));
        let users: User[] = JSON.parse(JSON.stringify(this.userList));

        // userListから所属メンバーのリスト作成
        let memberToUserList: User[] = [];

        members.forEach((member) => {
          const { memberId } = member;
          this.userList.forEach((user) => {
            if (memberId === user.userId) {
              memberToUserList.push(user);
            }
          });
        });
        memberToUserList = JSON.parse(JSON.stringify(memberToUserList));
        const deleteUserIds: string[] = [];
        if (this.memberList.length > 0) {
          memberToUserList.forEach((member) => {
            deleteUserIds.push(member.userId);
          });
          // 所属メンバー以外のメンバーのリスト作成
          users = users.filter((user) => {
            return !deleteUserIds.includes(user.userId);
          });
        }
        // 本登録ユーザーのみ表示
        users = users.filter((user) => {
          return user.status !== 0;
        });
        // 検索のためにプロジェクト名を追加
        users.forEach((user: User) => {
          if (user.projects) {
            const name = user.projects.map((elm: { projectName: string }) => elm.projectName);
            this.$set(user, 'projectName', name);
          }
        });
        this.addMemberList = users;
        this.showedDialog.memberList = false;
        this.showedDialog.addMemberList = true;
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    async addMember(item: User) {
      try {
        this.setNavigating({ navigating: true });

        const updateMemberParams = {
          workspaceId: item.workspaceId,
          projectId: this.projectInfo.projectId,
          memberId: item.userId,
        };
        await ProjectMemberService.createProjectMember(updateMemberParams);

        this.showedDialog.addMemberList = false;
        this.showMemberDialog();

        this.setNavigating({ navigating: false });
        this.setMessage({
          color: 'success',
          text: 'メンバーを追加しました',
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // プロジェクト編集ダイアログ表示
    showUpdateDialog(item: Project) {
      this.projectInfo = item;
      this.editParams.projectName = item.projectName;
      this.showedDialog.update = true;
    },

    async updateProject() {
      try {
        this.$v.$touch();

        if (this.$v.editParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        const params = {
          projectName: this.editParams.projectName,
        };
        await ProjectService.updateProject(workspaceId, this.projectInfo.projectId, params);

        this.setTableItem();
        this.closeDialog('update');
        this.setMessage({
          color: 'success',
          text: `${MESSAGE_CHANGE_DONE}`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // プロジェクト削除ダイアログ表示
    async showDeleteDialog(item: Project) {
      this.showWarning = false;
      this.warningMessage = '';
      try {
        this.projectInfo = item;
        // メンバーを取得
        const { workspaceId } = this.userAttributes;
        const id = item.projectId;
        const members: Member[] = await ProjectMemberService.getProjectMemberList(workspaceId, id);
        if (members.length > 0) {
          this.warningMessage = 'このプロジェクトにはメンバーがいます。削除しますか？';
          this.showWarning = true;
        }
        this.showedDialog.delete = true;
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // プロジェクト削除
    async deleteProject() {
      try {
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        await ProjectService.deleteProject(workspaceId, this.projectInfo.projectId);

        this.setTableItem();
        this.setMessage({ color: 'success', text: 'プロジェクトを削除しました。' });
      } catch (error) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
        this.setNavigating({ navigating: false });
      }
    },

    closeDialog(name: string) {
      const key = name;
      this.showedDialog[key] = false;
      switch (name) {
        case 'create':
          this.$v.$reset();
          this.createNewParams.projectName = '';
          break;
        case 'update':
          this.$v.$reset();
          this.editParams.projectName = '';
          break;
        default:
          break;
      }
    },
  },
  validations() {
    return {
      createNewParams: {
        projectName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
      },
      editParams: {
        projectName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
      },
    };
  },
});
