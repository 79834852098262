












































import Vue from 'vue';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import WorkspaceService from '@/services/ui/WorkspaceService';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';

const ImageService = ServiceFactory.get('image');

export default Vue.extend({
  name: 'MWorkspace',

  data(): {
    // 初期値設定のためanyを許容
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    file: any;
    imageRemoved: boolean;
    isSelecting: boolean;
    previewUrl: string;
    updateParams: {
      workspaceName: string;
      image?: string;
    };
  } {
    return {
      file: '',
      imageRemoved: false,
      isSelecting: false,
      previewUrl: '',
      updateParams: {
        workspaceName: '',
      },
    };
  },
  computed: {
    ...UIWorkspaceMapper.mapState(['workspace']),

    previewImage(): string {
      if (this.imageRemoved) {
        return '';
      }
      return this.previewUrl ? this.previewUrl : this.workspace.image;
    },
  },
  created() {
    this.updateParams.workspaceName = this.workspace.workspaceName;
  },
  methods: {
    ...UICommonMapper.mapActions(['setMessage']),
    ...UIWorkspaceMapper.mapActions(['setWorkspaceSpecified']),

    handleFileImport() {
      this.isSelecting = true;
      this.imageRemoved = false;

      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      const element = this.$refs.preview as HTMLInputElement;
      element.click();
    },

    removeImage() {
      const element = this.$refs.preview as HTMLInputElement;
      // リセット
      element.type = 'text';
      element.type = 'file';
      this.file = '';
      this.previewUrl = '';
      this.imageRemoved = true;
    },

    showPreview() {
      const element = this.$refs.preview as HTMLInputElement;
      if (this._.isNull(element.files)) {
        return;
      }

      // ファイルは一つ
      // eslint-disable-next-line prefer-destructuring
      this.file = element.files[0];
      this.previewUrl = URL.createObjectURL(this.file);
    },

    async update() {
      const { workspaceId } = this.workspace;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$$log.debug(this.$v);
        return;
      }

      this.updateParams.workspaceName = this._.trim(this.updateParams.workspaceName);
      const params = { ...this.updateParams };

      try {
        if (this.file) {
          const responseImage = await ImageService.uploadImage(
            this.workspace.workspaceId,
            'workspace',
            this.file,
            {
              height: 180,
              width: 180,
            }
          );
          params.image = responseImage.url;
        }
        if (this.imageRemoved) {
          params.image = '';
        }

        const response = await WorkspaceService.updateWorkspace(workspaceId, params);

        this.setMessage({
          color: 'success',
          text: MESSAGE_CHANGE_DONE,
        });

        this.setWorkspaceSpecified(response);
      } catch (error) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }
    },
  },
  validations() {
    return {
      updateParams: {
        workspaceName: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          minLength: minLength(2),
          maxLength: maxLength(32),
          /* eslint-enable vue/sort-keys */
        },
      },
    };
  },
});
