
















































































































































































import { mdiSort, mdiCheck, mdiPlus, mdiClose, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js';
import Vue from 'vue';
import Draggable from 'vuedraggable';
import { minLength, maxLength } from 'vuelidate/lib/validators';
import { MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIPositionMapper } from '@/store/modules/ui/position';
import type { Position } from '@/store/modules/ui/position';

const PositionService = ServiceFactory.get('position');

export default Vue.extend({
  name: 'MPositionList',
  components: { Draggable },

  data(): {
    icons: {
      [key: string]: string;
    };
    items: Position[];
    editing: boolean;
    orders: string[];
    showedDialog: {
      [key: string]: boolean;
    };
    positionInfo: Position;
    createNewParams: {
      positionName: string;
    };
    editParams: {
      positionName: string;
    };
  } {
    return {
      // 表示順序で記述
      /* eslint-disable vue/sort-keys */
      icons: {
        mdiClose,
        mdiSort,
        mdiCheck,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
      items: [],
      editing: false,
      orders: [],
      showedDialog: {
        create: false,
        update: false,
        delete: false,
      },
      positionInfo: { positionId: '', name: '', regDate: '', workspaceId: '', status: 1 },
      createNewParams: {
        positionName: '',
      },
      editParams: {
        positionName: '',
      },
      /* eslint-enable vue/sort-keys */
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIPositionMapper.mapState(['positionList']),
  },

  created() {
    this.setItem();
  },
  methods: {
    ...UIPositionMapper.mapActions(['setPositionList']),
    ...UICommonMapper.mapActions(['setNavigating', 'setErrorMessage', 'setMessage']),

    // 表示順序で記述
    /* eslint-disable vue/sort-keys */
    async setItem() {
      try {
        this.setNavigating({ navigating: true });
        const { workspaceId } = this.userAttributes;

        // 役職一覧を取得
        const list = await PositionService.getPositionList(workspaceId);
        this.items = JSON.parse(JSON.stringify(list));
        // 役職情報をストアにセット
        this.setPositionList(list);
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        if (error.status !== 404) {
          this.setMessage({ color: 'error', text: error.message });
        } else {
          this.items = [];
        }
        this.setNavigating({ navigating: false });
      }
    },

    async action(e: string) {
      if (e === 'done') {
        const { workspaceId } = this.userAttributes;
        this.setNavigating({ navigating: true });
        try {
          const { orders } = this;
          const params = {
            workspaceId,
            orders,
          };

          await PositionService.updatePositionOrder(params);

          this.setItem();
          this.setMessage({ color: 'success', text: `${MESSAGE_CHANGE_DONE}` });
          this.editing = false;
        } catch (error) {
          this.$$log.error(error);
          this.setNavigating({ navigating: false });
          this.setMessage({ color: 'error', text: error.message });
        }
      } else if (e === 'sort') {
        this.editing = true;
      } else if (e === 'undo') {
        this.editing = false;
        this.items = JSON.parse(JSON.stringify(this.positionList));
      }
    },
    reorder() {
      this.orders = [];
      this.items.forEach((item) => {
        this.orders.push(item.positionId);
      });
    },

    // 役職追加
    async createPosition() {
      try {
        this.$v.$touch();

        if (this.$v.createNewParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        const params = {
          workspaceId,
          name: this.createNewParams.positionName,
        };
        await PositionService.createPosition(params);
        this.closeDialog('create');
        this.setItem();
        this.setMessage({
          color: 'success',
          text: `新しい役職を追加しました`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    toDialog(item: Position, name: string) {
      const key = name;
      this.showedDialog[key] = true;
      this.positionInfo = item;
      if (name === 'update') {
        this.editParams.positionName = item.name;
      }
    },

    async updatePosition() {
      try {
        this.$v.$touch();

        if (this.$v.editParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        const params = {
          name: this.editParams.positionName,
        };
        await PositionService.updatePosition(workspaceId, this.positionInfo.positionId, params);
        this.closeDialog('update');
        this.setItem();
        this.setMessage({
          color: 'success',
          text: `${MESSAGE_CHANGE_DONE}`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // 役職削除
    async deletePosition() {
      try {
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        await PositionService.deletePosition(workspaceId, this.positionInfo.positionId);
        this.closeDialog('delete');
        this.setItem();
        this.setMessage({ color: 'success', text: '役職を削除しました。' });
      } catch (error) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
        this.setNavigating({ navigating: false });
      }
    },

    closeDialog(name: string) {
      const key = name;
      this.showedDialog[key] = false;
      switch (name) {
        case 'create':
          this.$v.$reset();
          this.createNewParams.positionName = '';
          break;
        case 'update':
          this.$v.$reset();
          this.editParams.positionName = '';
          break;
        default:
          break;
      }
    },
  },
  validations() {
    return {
      createNewParams: {
        positionName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
      },
      editParams: {
        positionName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
      },
    };
  },
});
/* eslint-enable vue/sort-keys */
