// import colors from 'vuetify/lib/util/colors';

// ユーザー管理レベル
export const ADMIN_LEVEL = [
  {
    color: 'danger',
    name: 'super admin',
    value: 1,
  },
  {
    color: 'warning',
    name: 'admin',
    value: 2,
  },
  {
    color: 'info',
    name: '一般ユーザー',
    value: 3,
  },
];

// 出退勤
export const ATTENDANCE_KIND = [
  {
    name: '出勤',
    value: 1,
  },
  {
    name: '退勤',
    value: 2,
  },
];

// 更新完了メッセージ
export const MESSAGE_UPDATE_DONE = '更新しました。';

// 変更完了メッセージ
export const MESSAGE_CHANGE_DONE = '変更しました。';

// 打刻修正完了メッセージ
export const MESSAGE_UPDATE_ATTENDANCE_DONE = '打刻修正しました。';

// メール送信完了メッセージ
export const MESSAGE_SEND_EMAIL_DONE = 'メールを送信しました。';

// パスワード再設定完了メッセージ
export const MESSAGE_PASSWORD_RESET_DONE = 'パスワードを再設定しました。';

// アカウント無効エラーメッセージ
export const MESSAGE_ACCOUNT_DISABLED = 'アカウントが無効です。';

// パスワード入力ヒント
export const HINT_PASSWORD_COMPLEX =
  '半角英数字記号8文字以上50文字以下 (英小文字数字記号をそれぞれ含む) である必要があります。記号は=+-^$*.[]{}()?"!@#%&/\\,><\':;|_~`が使えます。';

// クリップボードコピー成功メッセージ
export const MESSAGE_CLIPBOARD_COPY_SUCCESS = 'クリップボードにコピーしました。';

// クリップボードコピー失敗メッセージ
export const MESSAGE_CLIPBOARD_COPY_ERROR = 'クリップボードへのコピーに失敗しました。';

// WebPush通知 受信タイトル
export const TITLE_WEB_PUSH_RECEIVED = 'Shine Connectからのメッセージです。';

// WebPush通知 クイックチャット受信メッセージ
export const MESSAGE_QUICK_CHAT_RECEIVED = 'クイックチャットを受信しました。';
