




































































































































































































































































































































































































































import {
  mdiVectorCircle,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiAccount,
  mdiAccountGroupOutline,
  mdiAccountPlusOutline,
  mdiStar,
  mdiAccountStarOutline,
  mdiPlus,
} from '@mdi/js';
import Vue from 'vue';
import { minLength, maxLength } from 'vuelidate/lib/validators';
import IconSearch from '@/components/specific/IconSearch.vue';
import { MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper, User } from '@/store/modules/ui/common';
import { Department, UIDepartmentMapper } from '@/store/modules/ui/department';

const UserService = ServiceFactory.get('user');
const DepartmentService = ServiceFactory.get('department');
const DepartmentMemberService = ServiceFactory.get('departmentMember');

interface Member {
  departmentId: string;
  departments: { departmentId: string; departmentName: string; leader: number }[];
  email?: string;
  image?: string;
  kana?: string;
  leader: number;
  memberId: string;
  regDate: string;
  userName?: string;
  workspaceId: string;
}

export default Vue.extend({
  name: 'MDepartmentList',
  components: {
    IconSearch,
  },
  data(): {
    icons: {
      [key: string]: string;
    };
    search: string;
    searchMember: string;
    headers: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    tableItems: {
      _id: '';
      workspaceId: '';
      departmentId: '';
      departmentName: '';
      regDate: '';
      members: Member[];
      leaders: string[];
    }[];
    memberList: Member[];
    memberListHeaders: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    addMemberList: User[];
    addMemberListHeaders: {
      text: string;
      sortable: boolean;
      value: string;
      width: string;
    }[];
    showedDialog: {
      [key: string]: boolean;
    };
    createNewParams: {
      departmentName: string;
    };
    departmentInfo: Department;
    isProcessing: boolean;
    showWarning: boolean;
    warningMessage: string;
    editParams: {
      departmentName: string;
    };
  } {
    return {
      // 表示順序で記述
      /* eslint-disable vue/sort-keys */
      icons: {
        mdiAccount,
        mdiAccountGroupOutline,
        mdiVectorCircle,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiAccountStarOutline,
        mdiAccountPlusOutline,
        mdiStar,
        mdiPlus,
      },
      search: '',
      searchMember: '',
      headers: [
        {
          text: '部署名',
          sortable: false,
          value: 'departmentName',
          width: '',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '150',
        },
      ],
      tableItems: [],
      memberList: [],
      memberListHeaders: [
        {
          text: '',
          sortable: false,
          value: 'leader',
          width: '50',
        },
        {
          text: '',
          sortable: false,
          value: 'image',
          width: '70',
        },
        {
          text: '',
          sortable: false,
          value: 'userName',
          width: '',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '40',
        },
      ],
      addMemberList: [],
      addMemberListHeaders: [
        {
          text: '',
          sortable: false,
          value: 'image',
          width: '70',
        },
        {
          text: '',
          sortable: false,
          value: 'userName',
          width: '',
        },
        {
          text: '',
          sortable: false,
          value: 'position',
          width: '150',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '40',
        },
      ],
      showedDialog: {
        create: false,
        update: false,
        delete: false,
        memberList: false,
        addMemberList: false,
      },
      createNewParams: {
        departmentName: '',
      },
      departmentInfo: {
        _id: '',
        workspaceId: '',
        departmentId: '',
        departmentName: '',
        regDate: '',
      },
      isProcessing: false,
      showWarning: false,
      warningMessage: '',
      editParams: {
        departmentName: '',
      },
      /* eslint-enable vue/sort-keys */
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['userList']),
    ...UIDepartmentMapper.mapState(['departmentList']),
  },
  created() {
    this.setTableItem();
  },

  methods: {
    ...UIDepartmentMapper.mapActions(['setDepartmentList']),
    ...UICommonMapper.mapActions(['setUserList', 'setNavigating', 'setErrorMessage', 'setMessage']),

    // 表示順序で記述
    /* eslint-disable vue/sort-keys */

    async setTableItem() {
      try {
        this.setNavigating({ navigating: true });

        // 部署一覧を取得
        const { workspaceId } = this.userAttributes;
        const departmentList = await DepartmentService.getDepartmentList(workspaceId);

        // 部署情報をストアにセット
        this.setDepartmentList({ departments: departmentList });
        this.tableItems = departmentList;

        // モーダル表示リセット
        Object.assign(this.showedDialog, {
          create: false,
          update: false,
          delete: false,
          memberList: false,
        });
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    customFilter(value: string, search: string, item: Record<string, unknown>) {
      // 検索対象のkey
      const list: string[] = ['email', 'userName', 'kana', 'departmentName', 'position'];
      // 検索対象のvalueのみをセット
      const filterItem: string[] = [];
      Object.entries(item).forEach(([key, fromValue]) => {
        list.forEach((i) => {
          if (key === i && typeof fromValue === 'string') {
            filterItem.push(fromValue);
          }
        });
      });
      const searchText = search.replace(/[\u3041-\u3096]/g, (ch) =>
        String.fromCharCode(ch.charCodeAt(0) + 0x60)
      );
      const result = filterItem.some((v) => v && v.toString().toLowerCase().includes(searchText));

      return result;
    },

    // 部署追加
    async createDepartment() {
      try {
        this.$v.$touch();

        if (this.$v.createNewParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        const params = {
          workspaceId,
          departmentName: this.createNewParams.departmentName,
        };
        await DepartmentService.createDepartment(params);

        this.setTableItem();
        this.closeDialog('create');
        this.setMessage({
          color: 'success',
          text: `新しい部署を作成しました`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    userNameShorten(user: Member): string {
      // TODO 実際には必ず名前は入っているので、このコードはリリース前に修正する
      return this._.get(user, 'userName', '不明').substring(0, 2);
    },

    // メンバー設定
    async showMemberDialog(item?: Department) {
      this.searchMember = '';
      try {
        this.setNavigating({ navigating: true });
        if (item) {
          this.departmentInfo = item;
        }
        const { workspaceId, departmentId } = this.departmentInfo;
        // ユーザーリストを取得
        const responseUserList = await UserService.getUserList(workspaceId);

        // ユーザー一覧をストアにセット
        const sortedUserList = this._.sortBy(responseUserList, 'kana');
        this.setUserList({ users: sortedUserList });

        const list = JSON.parse(JSON.stringify(sortedUserList));
        list.forEach((user: User) => {
          if (user.departments) {
            const name = user.departments.map(
              (elm: { departmentName: string }) => elm.departmentName
            );
            this.$set(user, 'departmentName', name);
          }
        });
        // メンバーを取得
        const members: Member[] = await DepartmentMemberService.getDepartmentMemberList(
          workspaceId,
          departmentId
        );
        // メンバーに必要な情報をユーザー情報から取得し追加
        members.forEach((member, index) => {
          const { memberId } = member;
          this.userList.forEach((user) => {
            if (memberId === user.userId) {
              members[index].image = user.image;
              members[index].userName = user.userName;
              members[index].kana = user.kana;
              members[index].email = user.email;
              members[index].departments = user.departments;
            }
          });
        });
        members.sort((a, b) => b.leader - a.leader);
        this.memberList = members;
        this.showedDialog.memberList = true;
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    async updateLeader(item: Member) {
      try {
        this.setNavigating({ navigating: true });
        let updateMemberParams: {
          workspaceId: string;
          departmentId: string;
          memberId: string;
          leader: number;
        };
        if (item.leader === 0) {
          updateMemberParams = {
            workspaceId: item.workspaceId,
            departmentId: item.departmentId,
            memberId: item.memberId,
            leader: 1,
          };
        } else {
          updateMemberParams = {
            workspaceId: item.workspaceId,
            departmentId: item.departmentId,
            memberId: item.memberId,
            leader: 0,
          };
        }
        await DepartmentMemberService.deleteDepartmentMember(
          item.workspaceId,
          item.departmentId,
          item.memberId
        );
        await DepartmentMemberService.createDepartmentMember(updateMemberParams);
        this.showMemberDialog();

        this.setMessage({
          color: 'success',
          text: `${MESSAGE_CHANGE_DONE}`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // メンバー追加
    async showAddMemberDialog() {
      try {
        this.setNavigating({ navigating: true });

        const members: Member[] = JSON.parse(JSON.stringify(this.memberList));
        let users: User[] = JSON.parse(JSON.stringify(this.userList));

        // userListから所属メンバーのリスト作成
        let memberToUserList: User[] = [];

        members.forEach((member) => {
          const { memberId } = member;
          this.userList.forEach((user) => {
            if (memberId === user.userId) {
              memberToUserList.push(user);
            }
          });
        });
        memberToUserList = JSON.parse(JSON.stringify(memberToUserList));
        const deleteUserIds: string[] = [];
        if (this.memberList.length > 0) {
          memberToUserList.forEach((member) => {
            // 所属メンバーのuserIdリスト作成
            deleteUserIds.push(member.userId);
          });
          // 所属メンバー以外のユーザーリスト作成
          users = users.filter((user) => {
            return !deleteUserIds.includes(user.userId);
          });
        }
        // 本登録ユーザーのみ表示
        users = users.filter((user) => {
          return user.status !== 0;
        });
        // 検索のために部署名を追加
        users.forEach((user: User) => {
          if (user.departments) {
            const name = user.departments.map(
              (elm: { departmentName: string }) => elm.departmentName
            );
            this.$set(user, 'departmentName', name);
          }
        });
        this.addMemberList = users;
        this.showedDialog.memberList = false;
        this.showedDialog.addMemberList = true;
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    async addMember(item: User) {
      try {
        this.setNavigating({ navigating: true });

        await DepartmentMemberService.deleteDepartmentMember(
          item.workspaceId,
          item.departments[0].departmentId,
          item.userId
        );

        const updateMemberParams = {
          workspaceId: item.workspaceId,
          departmentId: this.departmentInfo.departmentId,
          memberId: item.userId,
          leader: 0,
        };
        await DepartmentMemberService.createDepartmentMember(updateMemberParams);

        this.showedDialog.addMemberList = false;
        this.showMemberDialog();

        this.setNavigating({ navigating: false });
        this.setMessage({
          color: 'success',
          text: 'メンバーを追加しました',
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // 部署編集
    showUpdateDialog(item: Department) {
      this.departmentInfo = item;
      this.editParams.departmentName = item.departmentName;
      this.showedDialog.update = true;
    },

    async updateDepartment() {
      try {
        this.$v.$touch();

        if (this.$v.editParams.$invalid) {
          this.$$log.debug(this.$v);
          return;
        }
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        const params = {
          departmentName: this.editParams.departmentName,
        };
        await DepartmentService.updateDepartment(
          workspaceId,
          this.departmentInfo.departmentId,
          params
        );

        this.setTableItem();
        this.closeDialog('update');
        this.setMessage({
          color: 'success',
          text: `${MESSAGE_CHANGE_DONE}`,
        });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // 部署削除
    async showDeleteDialog(item: Department) {
      this.showWarning = false;
      this.warningMessage = '';
      try {
        this.departmentInfo = item;
        // メンバーを取得
        const { workspaceId } = this.userAttributes;
        const id = item.departmentId;
        const members: Member[] = await DepartmentMemberService.getDepartmentMemberList(
          workspaceId,
          id
        );
        if (members.length > 0) {
          this.warningMessage = 'メンバーがいる場合は削除できません。';
          this.showWarning = true;
        }
        this.showedDialog.delete = true;
        this.setNavigating({ navigating: false });
      } catch (error) {
        this.$$log.error(error);
        this.setNavigating({ navigating: false });
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    // 部署削除
    async deleteDepartment() {
      try {
        this.setNavigating({ navigating: true });

        const { workspaceId } = this.userAttributes;
        await DepartmentService.deleteDepartment(workspaceId, this.departmentInfo.departmentId);

        this.setTableItem();
        this.setMessage({ color: 'success', text: '部署を削除しました。' });
      } catch (error) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
        this.setNavigating({ navigating: false });
      }
    },

    closeDialog(name: string) {
      const key = name;
      this.showedDialog[key] = false;
      switch (name) {
        case 'create':
          this.$v.$reset();
          this.createNewParams.departmentName = '';
          break;
        case 'update':
          this.$v.$reset();
          this.editParams.departmentName = '';
          break;
        default:
          break;
      }
    },
  },
  validations() {
    return {
      createNewParams: {
        departmentName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
      },
      editParams: {
        departmentName: {
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
      },
    };
  },
});
